jQuery.fn.extend({
    crhToggleLeftNav() {
        const leftnav = $('nav.sidebar-left');

        if (!leftnav.length) {
            // no leftnav, no point in having this button.
            // @ts-ignore
            this.remove();
            return;
        }

        const label = $('nav.sidebar-left .left-nav-header').text();
        // @ts-ignore
        this.text(label);

        // @ts-ignore
        this.click(() => {
            const modal = $('<div id="crh-leftnav-modal" class="modal fade"><div class="modal-dialog"><div class="modal-content"><div class="modal-body"><span class="close" data-dismiss="modal">x</span><nav class="sidebar-left"></nav></div></div></div></div>');

            modal.find('nav').html(leftnav.html());

            modal.appendTo('body');

            // Setup so that clicking on an internal anchor link (starts with '#')
            // manually hides the modal
            modal.find('a[href^="#"]').on('click', () => {
                modal.modal('hide');
            });

            // Setup a one-shot event handler to close the modal on window resizes.
            $(window).one('resize', () => {
                modal.modal('hide');
            });

            modal.on('hidden.bs.modal', () => {
                modal.remove();
            });

            modal.modal({ show: true, keyboard: true });
        });
    },
});


$(() => {
    // Setup button that triggers a popup leftnav on small width displays
    $('#crh-toggle-leftnav').crhToggleLeftNav();
});


/**
 * Close an opened drop-down menu
 *
 * @param target
 */
export const hideMenu = (target: EventTarget | HTMLElement) => {
    // No longer listen for keyboard ESC to close menu
    $('body').off('keyup.megamenu');

    const li = $(target);

    li.find('.dropdown-toggle').attr('aria-expanded', 'false');

    li.find('.dropdown-menu')
        .stop()
        .fadeTo(150, 0, function () {
            // can't just do a simple .hide(), conflicts with Bootstrap3 collapsed nav
            $(this)
                .css('display', '')
                .css('opacity', '');
        });
};


/**
 * Show one of the drop-down menus
 *
 * @param target
 */
export const showMenu = (target: EventTarget | HTMLElement) => {
    const li = $(target);

    li.find('.dropdown-toggle').attr('aria-expanded', 'true');

    li.find('.dropdown-menu')
        .stop()
        .fadeTo(150, 1)
        .show(400, () => {
            // Setup to listen for keyboard ESC to close opened menu
            //
            $('body').on('keyup.megamenu', (keyEvent) => {
                if (keyEvent.key === 'Escape') {
                    hideMenu(target);
                }
            });
        });
};
